import React from "react";

import Layout from "./shared/layout/Layout";
import HomePageLoader from "./shared/components/homePageLoader/HomePageLoader";

import "./shared/global.css";
import "./shared/configs/translation/i18nConfig";

const App = () => {
  return (
    <>
      <HomePageLoader />
      <Layout />
    </>
  );
};

export default App;
