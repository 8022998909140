import React from "react";

import Navbar from "./navbar/Navbar";
import Pages from "../configs/routes/Pages";

const Layout = () => {
  return (
    <div>
      <Navbar />
      <Pages />
    </div>
  );
};

export default Layout;
