import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./en/translation.json";
import translationRU from "./ru/translation.json";
import translationUZ from "./uz/translation.json";

const selectedLanguage = localStorage.getItem("language");

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  uz: {
    translation: translationUZ,
  },
};

i18n.use(initReactI18next).init({
  debug: false,
  lng: selectedLanguage || "en",
  keySeparator: "^",
  nsSeparator: "~",
  interpolation: {
    escapeValue: false,
  },
  resources: resources,
});

export default i18n;
