export const routes = [
  {
    path: "/",
    component: <div>Home</div>,
    isVisibleNavbar: false,
  },
  {
    path: "/about",
    component: <div>About</div>,
    title: "About me",
    isVisibleNavbar: true,
  },
  {
    path: "/services",
    component: <div>Services</div>,
    title: "Services",
    isVisibleNavbar: true,
  },
  {
    path: "/portfolio",
    component: <div>Portfolio</div>,
    title: "Portfolio",
    isVisibleNavbar: true,
  },
  {
    path: "/blog",
    component: <div>Blog</div>,
    title: "Blog",
    isVisibleNavbar: true,
  },
  {
    path: "*",
    component: <div>404</div>,
    isVisibleNavbar: false,
  },
];
