import React, { useState, useEffect } from "react";

import lightLogo from "../../../assets/image/light-logo.svg";
import darkLogo from "../../../assets/image/dark-logo.svg";

import "./HomePageLoader.css";

const HomePageLoader = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [logo, setLogo] = useState(
    window.localStorage.getItem("theme") === "dark" ? lightLogo : darkLogo
  );

  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setFadeOut(true);
    }, 2000);

    const removeTimer = setTimeout(() => {
      setIsVisible(false);
    }, 3500);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(removeTimer);
    };
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem("mode");
    setLogo(theme === "dark" ? darkLogo : lightLogo);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`loader_main ${fadeOut ? "fade-out" : ""}`}>
      <span className="home_page_loader"></span>
      <img src={logo} alt="Logo" />
    </div>
  );
};

export default HomePageLoader;
