import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

import "./ModeSwitcher.css";

const ModeSwitcher = ({ isDarkMode, handleToggle }) => {
  return (
    <div className="switcher" onClick={handleToggle}>
      <span className="switcher_icon">
        {isDarkMode ? (
          <Icon width={35} icon="solar:moon-bold" color="#EDFCFE" />
        ) : (
          <Icon
            width={35}
            icon="material-symbols:wb-sunny-rounded"
            color="#f8d538"
          />
        )}
      </span>
      <div
        className={`switcher_toggle ${isDarkMode ? "switcher_checked" : ""}`}
      >
        <div className="switcher_slider" />
      </div>
    </div>
  );
};

export default ModeSwitcher;
