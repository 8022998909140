import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { routes } from "./routes";

const Pages = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route, index) => (
          <Route
            path={route.path}
            element={route.component}
            key={`1201${index}`}
          />
        ))}
      </Routes>
    </Suspense>
  );
};

export default Pages;
